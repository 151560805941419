// const stuff = () => {
//   return "Hello!";
// };

// mpduule.exports = {
//   stuff
// };

const getName = () => {
  return "Jim";
};

module.exports = {
  getName
};

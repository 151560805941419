<template>
  <div id="app" class="container-fluid">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> | 
      <router-link to="/sink">Sink</router-link> | 
      <router-link to="/chat">Chat</router-link>
    </nav>
    <router-view/>
  </div>
</template>

<script>

import "bootstrap/dist/js/bootstrap.bundle.js";
// import "./style.scss";

export default {
  name: 'App',
  mounted() {
    const getName = require("./_helpers.js").getName;
    console.log(`Ok here we go ${getName()}!`);
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";
</style>

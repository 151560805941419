// import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";

import "./style.scss";

import Vue from "vue";

import App from "./App.vue";
import router from "./router.js";

const getName = require("./_helpers.js").getName;

Vue.config.productionTip = false;

console.log(getName());

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
